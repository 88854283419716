module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3N9PWRKN2M"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"ODQ3NDk3ZjMtNjQ2Mi00NGRjLWE0YzktMzgwZDU2ZGI1ZmU0NjM2OTA5NTAxNTI0NDM5NTMx","openCartOnAdd":true,"useSideCart":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"191243119331502"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
