// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-work-js": () => import("./../../../src/components/work.js" /* webpackChunkName: "component---src-components-work-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-commissions-js": () => import("./../../../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-image-license-js": () => import("./../../../src/pages/image-license.js" /* webpackChunkName: "component---src-pages-image-license-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-more-info-js": () => import("./../../../src/pages/more-info.js" /* webpackChunkName: "component---src-pages-more-info-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-small-works-js": () => import("./../../../src/pages/small-works.js" /* webpackChunkName: "component---src-pages-small-works-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-works-petite-cascade-js": () => import("./../../../src/pages/works/Petite-Cascade.js" /* webpackChunkName: "component---src-pages-works-petite-cascade-js" */)
}

